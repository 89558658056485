import React, {useContext} from "react";
import StyleContext from "../../contexts/StyleContext";
import "./ToggleSwitch.scss";
import DarkModeToggle from "react-dark-mode-toggle";

const ToggleSwitch = () => {
  const {isDark} = useContext(StyleContext);
  const styleContext = useContext(StyleContext);

  return (
    <DarkModeToggle
      onChange={() => {
        styleContext.changeTheme();
      }}
      checked={isDark}
      size={65}
    />
  );
};
export default ToggleSwitch;
