import React, {useContext, useState} from "react";
import "./StartupProjects.scss";
import {bigProjects} from "../../portfolio";
import {Fade} from "react-reveal";
import StyleContext from "../../contexts/StyleContext";
import Modal from "react-modal";
import AwesomeSlider from "react-awesome-slider";
import AwsSliderStyles from "react-awesome-slider/src/styles";
import emoji from "react-easy-emoji";

export default function StartupProject() {
  const [showModal, setShowModal] = useState(false);
  const [selectedProjectIdx, setSelectedProjectIdx] = useState();

  const selectProject = idx => {
    setSelectedProjectIdx(idx);
    setShowModal(true);
  };

  function openUrlInNewTab(url) {
    if (!url) {
      return;
    }
    const win = window.open(url, "_blank");
    win.focus();
  }

  const {isDark} = useContext(StyleContext);
  if (!bigProjects.display) {
    return null;
  }
  return (
    <Fade bottom duration={1000} distance="20px">
      <div className="main" id="projects">
        <div>
          <h1 className="skills-heading">{bigProjects.title}</h1>
          <p
            className={
              isDark
                ? "dark-mode project-subtitle"
                : "subTitle project-subtitle"
            }
          >
            {bigProjects.subtitle}
          </p>

          <Modal isOpen={showModal} onRequestClose={() => setShowModal(false)}>
            {selectedProjectIdx !== undefined && (
              <AwesomeSlider
                bullets
                mobileTouch
                interval={1000}
                cssModule={AwsSliderStyles}
              >
                {bigProjects.projects[selectedProjectIdx].images.map(img => (
                  <div data-src={img} />
                ))}
              </AwesomeSlider>
            )}
          </Modal>

          <div className="projects-container">
            {bigProjects.projects.map((project, i) => {
              return (
                <div
                  key={i}
                  role={"button"}
                  onClick={() => selectProject(i)}
                  className={
                    isDark
                      ? "dark-mode project-card project-card-dark"
                      : "project-card project-card-light"
                  }
                >
                  {project.image ? (
                    <div className="project-image">
                      {/*<figure>*/}
                      <img
                        src={project.image}
                        alt={project.projectName}
                        className="card-image"
                      />
                      <div className={
                        isDark
                          ? "dark-mode project-image-after"
                          : "project-image-after"
                      }>
                        <span>{emoji("See More 👀")} </span>
                      </div>
                      {/*</figure>*/}
                    </div>
                  ) : null}

                  <div className="project-detail">
                    <h5
                      className={isDark ? "dark-mode card-title" : "card-title"}
                    >
                      {project.projectName}
                    </h5>

                    <p
                      className={
                        isDark ? "dark-mode card-subtitle" : "card-subtitle"
                      }
                    >
                      {project.projectDesc}
                    </p>
                    {project.footerLink ? (
                      <div className="project-card-footer">
                        {project.footerLink.map((link, i) => {
                          return (
                            <span
                              key={i}
                              className={
                                isDark ? "dark-mode project-tag" : "project-tag"
                              }
                              onClick={() => openUrlInNewTab(link.url)}
                            >
                              {link.name}
                            </span>
                          );
                        })}
                      </div>
                    ) : null}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Fade>
  );
}
