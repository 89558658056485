/* Change this file to get your personal Portfolio */

// To change portfolio colors globally go to the  _globalColor.scss file

import emoji from "react-easy-emoji";

// Summary And Greeting Section

const illustration = {
  animated: true // Set to false to use static SVG
};
const startYear = new Date("2015");
const now = new Date();
const yDiff = now.getFullYear() - startYear.getFullYear();
const greeting = {
  username: "Rabee AbuBaker",
  title: "Hi, I'm Rabee",
  subTitle: emoji(
    `Senior Front-End R&D Engineer and Team Lead with ${yDiff}+ years of Frontend Development and a demonstrated history of working in the computer software industry. Skilled in JavaScript, TypeScript, Angular, React, Hybrid Mobile Apps Development and some other cool libraries and frameworks. I currently work as a Senior Front-End Engineer at DAZN`
  ),
  resumeLink:
    "https://docs.google.com/document/d/1-V7mqXJjmxevTMvjzsQyFdiCqez0VjCs0qiGnr7AKCU", // Set to empty to hide the button
  displayGreeting: true // Set false to hide this section, defaults to true
};

// Social Media Links

const socialMediaLinks = {
  github: "https://github.com/RabeeAbuBaker",
  linkedin: "https://www.linkedin.com/in/rabee-abubaker",
  gmail: "rabeeabubaker@gmail.com",
  stackoverflow: "https://stackoverflow.com/users/8149512/r-abubaker",
  skype: "skype:rabee.abubaker",
  whatsapp: "https://wa.me/972567100070",
  display: true // Set true to display this section, defaults to false
};

// Skills Section

const skillsSection = {
  title: "What I do",
  subTitle: "",
  skills: [
    emoji("⚡ Develop highly interactive Front end / User Interfaces"),
    emoji("⚡ Hybrid Mobile Apps Development (Ionic)"),
    emoji("⚡ Lead teams, build things from scratch and make decisions"),
    emoji("⚡ Research, analyze, plan and develop UI projects")
  ],

  /* Make Sure to include correct Font Awesome Classname to view your icon
https://fontawesome.com/icons?d=gallery */

  softwareSkills: [
    {
      skillName: "HTML",
      fontAwesomeClassname: "devicon-html5-plain"
    },
    {
      skillName: "CSS",
      fontAwesomeClassname: "devicon-css3-plain"
    },
    {
      skillName: "JavaScript (ES6)",
      fontAwesomeClassname: "devicon-javascript-plain"
    },
    {
      skillName: "Reactjs",
      fontAwesomeClassname: "devicon-react-original"
    },
    {
      skillName: "Nextjs",
      fontAwesomeClassname: "devicon-nextjs-original"
    },
    {
      skillName: "Angular",
      fontAwesomeClassname: "devicon-angularjs-plain"
    },
    {
      skillName: "TypeScript",
      fontAwesomeClassname: "devicon-typescript-plain"
    },
    {
      skillName: "Jest",
      fontAwesomeClassname: "devicon-jest-plain"
    },
    {
      skillName: "GraphQL",
      fontAwesomeClassname: "devicon-graphql-plain"
    },
    {
      skillName: "Tailwind CSS",
      fontAwesomeClassname: "devicon-tailwindcss-plain"
    },
    {
      skillName: "NPM",
      fontAwesomeClassname: "devicon-npm-original-wordmark"
    },
    {
      skillName: "Ionic",
      fontAwesomeClassname: "devicon-ionic-original"
    },
    {
      skillName: "Bootstrap",
      fontAwesomeClassname: "devicon-bootstrap-plain"
    },
    {
      skillName: "Git",
      fontAwesomeClassname: "devicon-git-plain"
    },
    {
      skillName: "Agile",
      fontAwesomeClassname: "fas fa-recycle"
    },
    {
      skillName: "Styled Components",
      fontAwesomeClassname: "far fa-star"
    },
    {
      skillName: "Webstorm",
      fontAwesomeClassname: "devicon-webstorm-plain"
    },
    {
      skillName: "Jira",
      fontAwesomeClassname: "devicon-jira-plain"
    },
    {
      skillName: "Webpack",
      fontAwesomeClassname: "devicon-webpack-plain"
    },
    {
      skillName: "Yarn",
      fontAwesomeClassname: "devicon-yarn-plain"
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Education Section

const educationInfo = {
  display: true, // Set false to hide this section, defaults to true
  schools: [
    {
      schoolName: "Birzeit University",
      logo: require("./assets/images/bzuLogo.png"),
      subHeader: "Bachelor's degree in Computer Science",
      duration: "2011 - 2015",
      desc: "University Honors Program - 4th year"
    }
  ]
};

// Your top 3 proficient stacks/tech experience

const techStack = {
  viewSkillBars: false, //Set it to true to show Proficiency Section
  experience: [
    {
      Stack: "Frontend/Design", //Insert stack or technology you have experience in
      progressPercentage: "90%" //Insert relative proficiency in percentage
    },
    {
      Stack: "Backend",
      progressPercentage: "70%"
    },
    {
      Stack: "Programming",
      progressPercentage: "60%"
    }
  ],
  displayCodersrank: false // Set true to display codersrank badges section need to changes your username in src/containers/skillProgress/skillProgress.js:17:62, defaults to false
};

// Work experience section

const workExperiences = {
  display: true, //Set it to true to show workExperiences Section
  experience: [
    {
      role: "Senior Front-End Engineer",
      company: "DAZN",
      companylogo: require("./assets/images/dazn.png"),
      date: "Sep 2022 – Present",
      desc: "DAZN Influencer project is live-streaming application that will integrate into the DAZN website, facilitating the provision of live audio and video-commentary by dedicated influencers alongside the primary event.",
      descBullets: [
        "Joined the Innovation Hub at DAZN to develop a new live-streaming app that will integrate into the DAZN website.",
        "Constructed the React-based frontend of the application from the ground up in 4 months, utilizing micro frontend infrastructure and Agora for seamless live-streaming.",
        "Wrote 80% of the application unit tests to enhance quality, reliability, and increase code coverage up to 90%.",
        "Contributed heavily to the front-end implementation of a voice-only streaming app leveraging Influencer infrastructure.",
        "Utilized React, TypeScript, PubNub, Agora, and Jest"
      ]
    },
    {
      role: "Senior Front-End Engineer",
      company: "CodeCov",
      companylogo: require("./assets/images/codeCov.png"),
      date: "Feb 2022 – Sep 2022",
      desc: "Codecov is a code analysis tool with which users can group, merge, archive, and compare coverage reports.",
      descBullets: [
        "Developed and rolled out new UI core features with comprehensive unit tests and reusable components.",
        "Groomed and refined Jira tickets, bridged Engineering and Product teams, and provided plans and estimates.",
        "Collaborated with product and UX teams on enhancing features and contributing to the product management process.",
        "Utilized React, TanStack Query, Jest, and GraphQL"
      ]
    },
    {
      role: "Software Engineer / Team Lead",
      company: "EXALT Technologies Ltd",
      companylogo: require("./assets/images/exaltLogo.png"),
      date: "September 2015 – Feb 2022",
      desc: "EXALT is a software development center of excellence specialized in Web Services, Backend/API Development, Mobile Apps and Validation.  Established in 1997, EXALT spearheaded the software development services industry to meet global market needs for innovative technologies.",
      descBullets: [
        "Started a junior developer with Exalt and worked on outsourced projects.",
        "Became responsible of training new hires and helping them touch base.",
        "Contribute in interviewing hire candidates.",
        "Promoted to Team Lead and took over multiple projects.",
        "Research, analyze, plan and estimate and manage development of several UI projects."
      ]
    },
    {
      role: "Senior Front-End R&D Engineer and Team Lead",
      company: "vFunction",
      companylogo: require("./assets/images/vFunctionLogo.png"),
      date: "Jan 2020 – Feb 2022",
      desc: "vFunction is a modernization platform for developers and architects that uniquely combines dynamic and static code analysis, data science and automation. This is done to help companies modernize their Java applications and transform them into microservices.",
      descBullets: [
        "UI features development and bug fixes.",
        "UI team management. Planning features, maintain best-practices and code reviews .",
        "Analyzing the requirements and breaking them down into tasks.",
        "Provide a plan and estimates for the tasks and deliveries.",
        "Contribute in designing and releasing the app."
      ]
    },
    {
      role: "Frontend Team lead / Development Supervisor",
      company: "Nokia",
      companylogo: require("./assets/images/nokiaLogo.jpg"),
      date: "Aug 2017 – Dec 2020",
      desc: "CloudBand Network Director is an NFV resource and network service orchestrator. It manages virtual resources across geo-distributed NFV infrastructure nodes. It visualizes and automates the lifecycle of network services in the virtual domain.",
      descBullets: [
        "UI features development and bug fixes.",
        "Project migration from Angular to React. Wrote the infrastructure of the new React application",
        "Took the responsibility to develop and maintain critical areas of the project guiding other three junior team members along.",
        "Analyzing the requirements and breaking them down into tasks.",
        "Provide a plan and estimates for the tasks and deliveries.",
        "Contribute in designing and releasing the app.",
        "Responsible for UI e2e testing.",
        "Promoted to Front-End Team Lead which implies shifting to another Nokia project ;Supervising three other engineers. And started to work at CBND project as Development Supervisor based on the management request."
      ]
    },
    {
      role: "Frontend R&D Engineer",
      company: "Ruckus Networks",
      companylogo: require("./assets/images/ruckusLogo.jpg"),
      date: "Sep 2015 – Jul 2017",
      desc: "Ruckus Cloud simplifies WLAN and LTE setup and management. Provisioning, monitoring and troubleshooting an entire Wi-Fi and LTE networks through a single web app.",
      descBullets: [
        "Started with the team as a junior developer and began to learn AngularJS 1.6 and Typescript. Evolved in a short time and took ownership of an important area of the project \"WLAN wizard\" .",
        "Shared in most sprints planning which includes analysis of features ,discussions with managers and UX designers. Gave hot-bug-fixes for the released product and had a vital role in developing complex cloud solutions.",
        "Researched and developed a POC for the conversion from AngularJS 1.6 to Angular 6 by creating a hybrid app and participating in the planning for the transition.",
        "Trained and Coached new employees who joined the front-end team. Exposed the new hires to ES6 and Typescript and GIT.",
        "Wrote the infrastructure of a new core feature in the application (LTE management)"
      ]
    }
  ]
};

/* Your Open Source Section to View Your Github Pinned Projects
To know how to get github key look at readme.md */

const openSource = {
  showGithubProfile: "true", // Set true or false to show Contact profile using Github, defaults to true
  display: false // Set false to hide this section, defaults to true
};

// Some big projects you have worked on

const bigProjects = {
  title: "Projects",
  subtitle: "Some projects that I have personally worked on",
  projects: [
    {
      image: require("./assets/images/projects/codecov_1.png"),
      projectName: "Codecov",
      projectDesc:
        "Codecov is a code analysis tool with which users can group, merge, archive, and compare coverage reports.",
      images: [
        require("./assets/images/projects/codecov_1.png"),
        require("./assets/images/projects/codecov_2.png"),
        require("./assets/images/projects/codecov_3.png"),
        require("./assets/images/projects/codecov_4.png"),
      ],
      footerLink: [
        {
          name: "Visit Website",
          url: "https://codecov.io/"
        }
      ]
    },
    {
      image: require("./assets/images/projects/vFuncion_1.png"),
      projectName: "vFunction",
      projectDesc:
        "vFunction modernizes Java applications and accelerates migration to the cloud",
      images: [
        require("./assets/images/projects/vFuncion_1.png"),
        require("./assets/images/projects/vFuncion_2.png"),
        require("./assets/images/projects/vFuncion_3.png"),
        require("./assets/images/projects/vFuncion_4.png"),
        require("./assets/images/projects/vFuncion_5.png"),
        require("./assets/images/projects/vFuncion_6.png"),
        require("./assets/images/projects/vFuncion_7.png"),
        require("./assets/images/projects/vFuncion_8.png"),
        require("./assets/images/projects/vFuncion_9.png")
      ],
      footerLink: [
        {
          name: "Visit Website",
          url: "https://vfunction.com/"
        }
      ]
    },
    {
      image: require("./assets/images/projects/cbnd_1.png"),
      projectName: "Nokia - CBND",
      projectDesc:
        "CloudBand Network Director is an NFV resource and network service orchestrator. It manages virtual resources across geo-distributed NFV infrastructure nodes. It visualizes and automates the lifecycle of network services in the virtual domain.",
      images: [
        require("./assets/images/projects/cbnd_1.png"),
        require("./assets/images/projects/cbnd_2.png"),
        require("./assets/images/projects/cbnd_4.png"),
        require("./assets/images/projects/cbnd_5.png"),
        require("./assets/images/projects/cbnd_6.png")
      ],
      footerLink: [
        {
          name: "Visit Website",
          url: "https://www.nokia.com/networks/solutions/cloudband/"
        }
      ]
    },
    {
      image: require("./assets/images/projects/nebula_1.png"),
      projectName: "Nebula (Internal Nokia startup)",
      projectDesc:
        "Nebula is an app that allows AWS users to optimize their usage of AWS services by calculating “Cost Avoidance Potential” for each service and alerts them whenever they are using the services inefficiently. This was an internal startup by NOKIA.",
      images: [
        require("./assets/images/projects/nebula_1.png"),
        require("./assets/images/projects/nebula_2.png")
      ]
    },
    {
      image: require("./assets/images/projects/ruckus_1.png"),
      projectName: "Ruckus Cloud",
      projectDesc:
        "Ruckus Cloud simplifies WLAN and LTE setup and management. Provisioning, monitoring and troubleshooting an entire Wi-Fi and LTE networks through a single web app.\n",
      images: [
        require("./assets/images/projects/ruckus_1.png"),
        require("./assets/images/projects/ruckus_2.png"),
        require("./assets/images/projects/ruckus_3.png"),
        require("./assets/images/projects/ruckus_4.png"),
        require("./assets/images/projects/ruckus_5.jpg")
      ],
      footerLink: [
        {
          name: "Visit Website",
          url: "https://support.ruckuswireless.com/products/94-ruckus-cloud"
        }
      ]
    }
  ],
  display: true
};

// Achievement Section
// Include certificates, talks etc

const achievementSection = {
  title: emoji("Achievements And Certifications 🏆 "),
  subtitle:
    "Achievements, Certifications, Award Letters and Some Cool Stuff that I have done !",

  achievementsCards: [
    {
      title: "Nokia (Creative Dayz) Hackathon - 1st Place ",
      subtitle:
        "Won an internal Nokia Hackathon from 16 participating teams, the hackathon idea was them adopted by the PLMS and integrated into the application.",
      image: require("./assets/images/nokiaLogo.jpg"),
      footerLink: [
        {
          name: "Hackathon Results",
          url: "https://i.ibb.co/PYwntxR/1573638776809.png"
        }
      ]
    }
    // {
    //   title: "Google Assistant Action",
    //   subtitle:
    //     "Developed a Google Assistant Action JavaScript Guru that is available on 2 Billion devices world wide.",
    //   image: require("./assets/images/googleAssistantLogo.webp"),
    //   footerLink: [
    //     {
    //       name: "View Google Assistant Action",
    //       url: "https://assistant.google.com/services/a/uid/000000100ee688ee?hl=en"
    //     }
    //   ]
    // },
    //
    // {
    //   title: "PWA Web App Developer",
    //   subtitle: "Completed Certifcation from SMIT for PWA Web App Development",
    //   image: require("./assets/images/pwaLogo.webp"),
    //   footerLink: [
    //     {name: "Certification", url: ""},
    //     {
    //       name: "Final Project",
    //       url: "https://pakistan-olx-1.firebaseapp.com/"
    //     }
    //   ]
    // }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Blogs Section

const blogSection = {
  display: false // Set false to hide this section, defaults to true
};

// Talks Sections

const talkSection = {
  title: "TALKS & PRESENTATIONS",
  subtitle: emoji(
    "I love to share my knowledge and present my work and experience 🤝"
  ),

  talks: [
    {
      title: "Intro to Web Development and Angular",
      subtitle: "University Trainees at Exalt Technologies Ltd. 2021",
      event_url:
        "https://www.linkedin.com/posts/rabee-abubaker_webdevelopment-activity-6830194075572936704-jkfE"
    },
    {
      title: "Intro to Web Development and Angular",
      subtitle:
        "University Trainees at Exalt Technologies Ltd. 2020. Done remotely via Zoom",
      event_url:
        "https://www.linkedin.com/posts/rabee-abubaker_angular-webdevelopment-frontend-activity-6718583219282833408-YhHx"
    },
    {
      title: "Meet & Greet Event / Intro to Web Development",
      subtitle: "University Students at Al-Najah University. 2020",
      event_url:
        "https://www.linkedin.com/posts/rabee-abubaker_meet-greet-at-an-najah-national-university-activity-6638110265827176448-xIv-"
    },
    {
      title: "Angular Hackathon",
      subtitle: "Presenting Angular for Exalt Hackathon competitors. 2019 ",
      event_url:
        "https://www.linkedin.com/posts/rabee-abubaker_frontend-angular-webdevelopment-activity-6581279707231211520-1M9l"
    },
    {
      title: "Pre-Angular Hackathon Session",
      subtitle:
        "Presenting Web Development principles for Exalt Hackathon competitors at Birzeit University. 2019 ",
      event_url:
        "https://www.linkedin.com/posts/rabee-abubaker_webdevelopment-activity-6580541610944192515-_vzK"
    },
    {
      title: "Angular and Cypress internal company event",
      subtitle:
        "Gave a session about Angular and Cypress during EXALT Technologies Ltd Lunch & Learn event. 2019 ",
      event_url:
        "https://www.linkedin.com/posts/rabee-abubaker_angular-cypress-activity-6509105588826304512-t2We"
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Podcast Section

const podcastSection = {
  display: false // Set false to hide this section, defaults to true
};

const contactInfo = {
  title: emoji("Get in touch ☎️"),
  subtitle: "Discuss a project or just want to chat? Feel free to reach out.",
  number: "+972-567100070",
  email_address: "rabee.abubaker@gmail.com"
};

// Twitter Section

const twitterDetails = {
  userName: "twitter", //Replace "twitter" with your twitter username without @
  display: false // Set true to display this section, defaults to false
};

export {
  illustration,
  greeting,
  socialMediaLinks,
  skillsSection,
  educationInfo,
  techStack,
  workExperiences,
  openSource,
  bigProjects,
  achievementSection,
  blogSection,
  talkSection,
  podcastSection,
  contactInfo,
  twitterDetails
};
