import React, {useContext} from "react";
import "./SoftwareSkill.scss";
import {skillsSection} from "../../portfolio";
import StyleContext from "../../contexts/StyleContext";

export default function SoftwareSkill() {
  const {isDark} = useContext(StyleContext);

  return (
    <div className="software-skills-main-div">
      <ul className="dev-icons">
        {skillsSection.softwareSkills.map(
          ({skillName, fontAwesomeClassname, icon}, i) => {
            return (
              <li key={i} className={`${isDark ? "dark-mode" : ""} software-skill-inline`} name={skillName}>
                <i className={fontAwesomeClassname} />
                <p>{skillName}</p>
              </li>
            );
          }
        )}
      </ul>
    </div>
  );
}
